.slick-next:before,
[dir="rtl"] .slick-prev:before {
  color: black;
}

.slick-prev:before {
  color: black;
}
.img {
  -webkit-transform: translate3d(0, 0, 0);
  /* -webkit-backface-visibility: hidden; */
  /* -ms-transform: translateZ(0); IE 9 */
  /* -webkit-transform: translateZ(0); Chrome, Safari, Opera */
  /* transform: translateZ(0); */
}
.partner .slick-slide {
  padding: 20px;
}
