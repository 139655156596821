.slick-slide img {
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .slick-dots {
    bottom: 9px;
  }

  .slick-dots li button:before {
    width: 0.38rem;
    height: 0.38rem;
    background: white;
    font-size: 0.38rem;
    line-height: 1.2rem;
    color: white;
    opacity: 1;
    border: 4px solid white;
    border-radius: 19px;
    content: "";
  }

  .slick-dots li button:before {
    width: 0.25rem;
    height: 0.25rem;
    background: white;
    font-size: 0.25rem;
    line-height: 1.2rem;
    color: blue;
    opacity: 1;
    border: 4px solid white;
    border-radius: 19px;
    content: "";
  }

  .slick-dots li.slick-active button:before {
    background: #08a9fb;
    background: linear-gradient(180deg, #08aafb 0%, #0745f8 107.5%);
    opacity: 1;
    font-size: 0.25rem;
  }
}

@media only screen and (min-width: 767px) {
  .slick-dots {
    bottom: 23px;
  }
  .slick-dots li {
    height: 2.2rem;
    width: 2.2rem;
  }
  .slick-dots li button {
    height: 2.2rem;
    width: 2.2rem;
  }

  .slick-dots li button:before {
    width: 1.2rem;
    height: 1.2rem;
    background: white;
    font-size: 0.625rem;
    line-height: 1.2rem;
    color: white;
    opacity: 1;
    border: 4px solid white;
    border-radius: 19px;
    content: "";
  }

  .slick-dots li.slick-active button:before {
    background: #08a9fb;
    background: linear-gradient(180deg, #08aafb 0%, #0745f8 107.5%);
    opacity: 1;
    font-size: 0.625rem;
  }
}

.centerAlign{
  left: 50%;
  transform: translate(-50%, -50%);
}