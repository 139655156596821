@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Made Tommy";
  src: url(assets/fonts/made-tommy.woff);
}

#root {
  font-family: "Made Tommy";
}

.headingText {
  background: linear-gradient(rgb(8, 170, 251) 0%, rgb(7, 69, 248) 107.5%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.madeTommyDark {
  font-family: "Made Tommy Bold";
}

@font-face {
  font-family: "Made Tommy Bold";
  src: url(assets/fonts/made-tommy-bold.woff);
}


.gradientText {
  background: var(--check, linear-gradient(180deg, #08aafb 0%, #0745f8 107.5%));
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
