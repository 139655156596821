.card h2 {
  margin: 0;
  margin-top: 1rem;
}

.card p {
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.card .btnn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
