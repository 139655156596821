.mainGroupImage{
  max-width: 100%;
  -webkit-transition: all 1s ease; /* Safari and Chrome */
  -moz-transition: all 1s ease; /* Firefox */
  -ms-transition: all 1s ease; /* IE 9 */
  -o-transition: all 1s ease; /* Opera */
  transition: all 1s ease;
}

.mainGroupImage:hover{
  -webkit-transform:scale(1.05); /* Safari and Chrome */
  -moz-transform:scale(1.05); /* Firefox */
  -ms-transform:scale(1.05); /* IE 9 */
  -o-transform:scale(1.05); /* Opera */
   transform:scale(1.05);
}