.hov:hover {
  background: linear-gradient(180deg, #08a9fb 20.44%, #0745f8 100%) !important;
}

.hov::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: linear-gradient(180deg, #08a9fb 20.44%, #0745f8 100%) !important;
  border-radius: 100%;
  opacity: 0;
}

@media only screen and (min-width: 1024px) {
  .btn-hover-0:hover::after {
    margin-left: -2.2%;
  }
  .btn-hover-1:hover::after {
    margin-left: -3.2%;
  }
  .btn-hover-2:hover::after {
    margin-left: -4.3%;
  }
  .btn-hover-3:hover::after {
    margin-left: -3.7%;
  }
  .btn-hover-4:hover::after {
    margin-left: -2%;
  }
  .btn-hover-5:hover::after {
    margin-left: -4%;
  }
  .hov:hover::after {
    opacity: 1;
    margin-top: 40px;
  }  
}

@media only screen and (min-width: 1280px) {
  .btn-hover-0:hover::after {
    margin-left: -2.5%;
  }
  .btn-hover-1:hover::after {
    margin-left: -3.2%;
  }
  .btn-hover-2:hover::after {
    margin-left: -4.5%;
  }
  .btn-hover-3:hover::after {
    margin-left: -3.5%;
  }
  .btn-hover-4:hover::after {
    margin-left: -2%;
  }
  .btn-hover-5:hover::after {
    margin-left: -4.2%;
  }
  .hov:hover::after {
    opacity: 1;
    margin-top: 55px;
  }  
}

@media only screen and (min-width: 1440px) {
  .btn-hover-0:hover::after {
    margin-left: -2.2%;
  }
  .btn-hover-4:hover::after {
    margin-left: -1.8%;
  }
  .btn-hover-5:hover::after {
    margin-left: -4%;
  }
}

@media only screen and (min-width: 1536px) {
  .btn-hover-0:hover::after {
    margin-left: -1.8%;
  }
  .btn-hover-1:hover::after {
    margin-left: -2.6%;
  }
  .btn-hover-2:hover::after {
    margin-left: -3.7%;
  }
  .btn-hover-3:hover::after {
    margin-left: -2.7%;
  }
  .btn-hover-4:hover::after {
    margin-left: -1.5%;
  }
  .btn-hover-5:hover::after {
    margin-left: -3.5%;
  }

  .hov:hover::after {
    opacity: 1;
    margin-top: 55px;
  }  
}